import React, { useEffect } from "react";
import HeaderComponent from "../../../components/header/header.component";
import { CasesProvider } from "../../../store";
import { CommonService } from "../../../services/common/common.service";
import { useTranslation } from "react-i18next";
import PagesHeadComponent from "../../../components/pages-head-component/pages-head.component";
import { icons } from "../../../statics/icons";

const AIAssessmentPage = () => {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("PAGE_META_TITLES.AI_RISK_ASSESSMENT"));

  useEffect(() => {
    // Dynamically load the Typeform script after the component mounts
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <CasesProvider>
      <HeaderComponent />
      <PagesHeadComponent
        title={t("PAGES_HEAD.AI_RISK_ASSESSMENT_TITLE")}
        subTitle={t("PAGES_HEAD.AI_RISK_ASSESSMENT_SUB_TITLE")}
        logo={icons.PAGES.HEAD.AI_RISK_ASSESSMENT}
      />
      {/* <ComingSoonComponent /> */}

      <div className="mt-5" data-tf-live="01J8KW7S6K9E3GWRY56S841Y2C"></div>
    </CasesProvider>
  );
};

export default AIAssessmentPage;
