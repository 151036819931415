export type Icons = {
  [key: string]: any;

  PAGES: {
    [key: string]: {
      [key: string]: any;
    };
  };
};

export const icons: Icons = {
  userImg: "/icons/profile.svg",
  usersImg: "/icons/users.svg",
  usersPrimary: "/icons/users-primary.svg",
  usersImgWhite: "/icons/user-white.svg",
  userImgLight: "/icons/profile-light.svg",
  reporterImg: "/icons/reporter.svg",

  amountOfDamage: "/icons/category/amount-of-damage.svg",
  amountOfDamagePrimary: "/icons/category/amount-of-damage-primary.svg",
  company: "/icons/category/company.svg",
  companyPrimary: "/icons/category/company-primary.svg",
  date: "/icons/category/date.svg",
  datePrimary: "/icons/category/date-primary.svg",
  relation: "/icons/category/relation.svg",
  relationPrimary: "/icons/category/relation-primary.svg",
  involvedDepartments: "/icons/category/involved-departments.svg",
  involvedDepartmentsPrimary:
    "/icons/category/involved-departments-primary.svg",
  involvedPeople: "/icons/category/involved-people.svg",
  involvedPeoplePrimary: "/icons/category/involved-people-primary.svg",
  category: "/icons/category/category.svg",
  categoryPrimary: "/icons/category/category-primary.svg",
  country: "/icons/category/country.svg",
  countryPrimary: "/icons/category/country-primary.svg",
  labels: "/icons/category/labels.svg",
  labelsPrimary: "/icons/category/labels-primary.svg",
  result: "/icons/category/result.svg",

  received: "/icons/case-status/received.svg",
  inProgress: "/icons/case-status/in-progress.svg",
  completed: "/icons/case-status/completed.svg",
  review: "/icons/case-status/review.svg",

  receivedPrimary: "/icons/case-status/received-primary.svg",
  inProgressPrimary: "/icons/case-status/in-progress-primary.svg",
  completedPrimary: "/icons/case-status/completed-primary.svg",
  reviewPrimary: "/icons/case-status/review-primary.svg",

  completedGrey: "/icons/case-status/completed-grey.svg",

  jpg: "/icons/file-formats/jpg.svg",
  mp3: "/icons/file-formats/mp3.svg",
  mp4: "/icons/file-formats/mp4.svg",
  pdf: "/icons/file-formats/pdf.svg",
  png: "/icons/file-formats/png.svg",
  jpeg: "/icons/file-formats/jpg.svg",

  de: "/icons/language/de.svg",
  en: "/icons/language/en.svg",

  bauhausLogo: "/icons/logos/logo-bauhaus.svg",
  hornbachLogo: "/icons/logos/logo-hornbach.svg",
  obiLogo: "/icons/logos/logo-obi.svg",

  dashboard: "/icons/nav/dashboard.svg",
  home: "/icons/nav/home.svg",
  profile: "/icons/nav/profile.svg",
  profilePrimary: "/icons/profile-primary.svg",
  profileLight: "/icons/nav/profile-light.svg",
  reports: "/icons/nav/reports.svg",
  trackReport: "/icons/nav/track-report.svg",
  users: "/icons/nav/users.svg",
  dashboardLight: "/icons/nav/dashboard-light.svg",
  homeLight: "/icons/nav/home-light.svg",
  reportsLight: "/icons/nav/reports-light.svg",
  trackReportLight: "/icons/nav/track-report-light.svg",
  trackReportPrimary: "/icons/nav/track-report-primary.svg",
  usersLight: "/icons/nav/users-light.svg",
  todo: "/icons/nav/todo.svg",
  todoPrimary: "/icons/nav/todo-primary.svg",
  todoLight: "/icons/nav/todo-light.svg",
  todoLightGrey: "/icons/todo-light-grey.svg",
  noteAddLight: "/icons/note-add.svg",
  noteAddPrimary: "/icons/note-add.svg",
  calender: "/icons/nav/calender.svg",
  calenderLight: "/icons/nav/calender-light.svg",
  todoComment: "/icons/todo-comment.svg",
  todoCommentPrimary: "/icons/todo-comment-primary.svg",

  logo: "/icons/logos/pAIper-logo.svg",
  logoWhite: "/icons/logos/pAIper-logo-white.svg",
  AI_ASSISTENT: "/icons/AI/AI-assistent.png",
  add: "/icons/add.svg",
  alert: "/icons/alert.svg",
  arrowForward: "/icons/arrow-forward.svg",
  check: "/icons/check.svg",
  checkWhite: "/icons/check-white.svg",
  checkPrimary: "/icons/check-primary.svg",
  checkLight: "/icons/check-light.svg",
  chevronDown: "/icons/chevron-down.svg",
  chevronUp: "/icons/chevron-up.svg",
  clock: "/icons/clock.svg",
  close: "/icons/close.svg",
  closeWhite: "/icons/close-white.svg",
  comment: "/icons/comment.svg",
  commentPrimary: "/icons/comment-primary.svg",
  datePicker: "/icons/date-picker.svg",
  download: "/icons/download.svg",
  downloadLight: "/icons/download-light.svg",
  downloadWhite: "/icons/download-white.svg",
  edit: "/icons/edit.svg",
  firstEditor: "/icons/first-editor.svg",
  help: "/icons/help.svg",
  info: "/icons/info.svg",
  infoDark: "/icons/info-dark.svg",
  more: "/icons/more-options.svg",
  notifications: "/icons/notifications.svg",
  off: "/icons/off.svg",
  offLight: "/icons/off-light.svg",
  otherEditors: "/icons/other-editors.svg",
  password: "/icons/password.svg",
  passwordPrimary: "/icons/password-primary.svg",
  search: "/icons/search.svg",
  searchWhite: "/icons/search-white.svg",

  send: "/icons/send.svg",
  settings: "/icons/settings.svg",
  settingsLight: "/icons/settings-light.svg",
  thirdParty: "/icons/third-party.svg",
  user: "/icons/user.svg",
  view: "/icons/view.svg",
  delete: "/icons/delete.svg",
  deletePrimary: "/icons/delete-primary.svg",
  support: "/icons/support.svg",
  supportAdmin: "/icons/martina-profile.svg",
  intern: "/icons/intern.svg",
  internGrey: "icons/intern-grey.svg",
  invite: "/icons/invite.svg",
  invitePrimary: "/icons/invite-primary.svg",
  inviteWhite: "/icons/invite-white.svg",
  copy: "/icons/copy.png",
  activateAI: "/icons/AI/activate-ai.svg",
  AIInProgress: "/icons/AI/ai-in-progress.gif",
  AIActivated: "/icons/AI/ai-activated.svg",
  brain: "/icons/AI/brain.svg",
  brainActive: "/icons/AI/brain-active.svg",
  downloadPrimary: "/icons/download-primary.svg",
  validated: "/icons/validated.svg",
  notValidated: "/icons/not-validated.svg",
  file: "/icons/file.svg",
  filePrimary: "/icons/file-primary.svg",
  warning: "/icons/warning.svg",
  play: "/icons/play.svg",
  playPrimary: "/icons/play-primary.svg",
  playWhite: "/icons/play-white.svg",
  playLight: "/icons/play-light.svg",

  AIDistributorPrimary: "/icons/create-case/AI-distributor-primary.svg",
  AIDistributorWhite: "/icons/create-case/AI-distributor-white.svg",

  AIImporterPrimary: "/icons/create-case/AI-distributor-primary.svg",
  AIImporterWhite: "/icons/create-case/AI-distributor-white.svg",

  AIDeveloperPrimary: "/icons/create-case/AI-developer-primary.svg",
  AIDeveloperWhite: "/icons/create-case/AI-developer-white.svg",

  AIUserPrimary: "/icons/create-case/AI-user-primary.svg",
  AIUserWhite: "/icons/create-case/AI-user-white.svg",
  // categoryPrimary: "/icons/create-case/category-primary.svg",
  categoryWhite: "/icons/create-case/category-white.svg",
  fileUpload: "/icons/file-upload.svg",

  ideaWhite: "/icons/idea-white.svg",

  PLACEHOLDER: {
    user: "/icons/placeholder/user.png",
    userWhite: "/icons/placeholder/user-white.svg",
  },
  PAGES: {
    HEAD: {
      AI_RISK_LANDSCAPE: "/icons/badges/AI-landscape.png",
      AI_PRODUCTS: "/icons/badges/AI-products.png",
      AI_INVENTORY: "/icons/badges/AI-inventory.png",
      AI_DEMAND: "/icons/badges/AI-demand.png",
      AI_TRACKING: "/icons/badges/AI-compliance-tracking.png",
      AI_RISK_ASSESSMENT: "/icons/badges/AI-risk-assessment.png",
      AI_TASKS: "/icons/badges/AI-tasks.png",
    },
    CASES: {
      AIProducts: "/icons/cases/AI-products.svg",
      AIInventory: "/icons/cases/AI-inventory.svg",
      AIDemand: "/icons/cases/AI-demand.svg",
      AIProductsWhite: "/icons/cases/AI-products-white.svg",
      AIInventoryWhite: "/icons/cases/AI-inventory-white.svg",
      PROCCESS: {
        NEW: "/icons/cases/progress/new.svg",
        DONE: "/icons/cases/progress/done.svg",
        ONGOING: "/icons/cases/progress/ongoing.svg",
      },
    },
    CASE_DETAILS: {
      openAI: "/icons/case-details/openAI.png",
    },
    SIDE_NAVE: {
      Dashboard: "/icons/nav/dashboard.svg",
      DashboardLight: "/icons/nav/dashboard-light.svg",
      AIProducts: "/icons/nav/ai-products.svg",
      AIProductsLight: "/icons/nav/ai-products-light.svg",
      AIInventory: "/icons/nav/ai-inventory.svg",
      AIInventoryLight: "/icons/nav/ai-inventory-light.svg",
      ListLight: "/icons/nav/reports-light.svg",
      List: "/icons/nav/reports.svg",
      Todo: "/icons/nav/todo.svg",
      TodoLight: "/icons/nav/todo-light.svg",
      AIDemand: "/icons/nav/ai-demand.svg",
      AIDemandLight: "/icons/nav/ai-demand-light.svg",
      Support: "/icons/nav/support.svg",
      SupportLight: "/icons/nav/support-light.svg",
      Warning: "/icons/nav/warning.svg",
      WarningLight: "/icons/nav/warning-light.svg",
    },
    SETTINGS: {
      country: "/icons/settings/country.svg",
      countryActive: "/icons/settings/country-active.svg",
      status: "/icons/settings/status.svg",
      statusActive: "/icons/settings/status-active.svg",
      resolution: "/icons/settings/resolution.svg",
      resolutionActive: "/icons/settings/resolution-active.svg",
      relationship: "/icons/settings/relationship.svg",
      relationshipActive: "/icons/settings/relationship-active.svg",
      category: "/icons/settings/category.svg",
      categoryActive: "/icons/settings/category_active.svg",
      areas: "/icons/settings/areas.svg",
      areasActive: "/icons/settings/areas-active.svg",
      techniques: "/icons/settings/techniques.svg",
      techniquesActive: "/icons/settings/techniques-active.svg",
      machineLearning: "/icons/settings/machine-learning.svg",
      machineLearningActive: "/icons/settings/machine-learning-active.svg",
      algorithm: "/icons/settings/algorithm.svg",
      algorithmActive: "/icons/settings/algorithm-active.svg",
      operation: "/icons/settings/operation.svg",
      operationActive: "/icons/settings/operation-active.svg",
      ICON_OPTIONS: {
        // AREAS ICONS
        CHATBOT: "/icons/settings/areas/chatbot.svg",
        CHATBOT_WHITE: "/icons/settings/areas/chatbot-white.svg",
        IMAGE_GENERATION: "/icons/settings/areas/image-generation.svg",
        IMAGE_GENERATION_WHITE:
          "/icons/settings/areas/image-generation-white.svg",
        IMAGE_RECOGNITION: "/icons/settings/areas/image-recognition.svg",
        IMAGE_RECOGNITION_WHITE:
          "/icons/settings/areas/image-recognition-white.svg",
        IMAGE_SEGMENTATION: "/icons/settings/areas/image-segmentation.svg",
        IMAGE_SEGMENTATION_WHITE:
          "/icons/settings/areas/image-segmentation-white.svg",
        OBJECT_DETECTION: "/icons/settings/areas/object-detection.svg",
        OBJECT_DETECTION_WHITE:
          "/icons/settings/areas/object-detection-white.svg",
        PROCESS_AUTOMATION: "/icons/settings/areas/process-automation.svg",
        PROCESS_AUTOMATION_WHITE:
          "/icons/settings/areas/process-automation-white.svg",
        RECOMMENDATION_SYSTEM:
          "/icons/settings/areas/recommendation-system.svg",
        RECOMMENDATION_SYSTEM_WHITE:
          "/icons/settings/areas/recommendation-system-white.svg",
        ROBOTICS: "/icons/settings/areas/robotics.svg",
        ROBOTICS_WHITE: "/icons/settings/areas/robotics-white.svg",
        SEMANTIC_SEARCH: "/icons/settings/areas/semantic-search.svg",
        SEMANTIC_SEARCH_WHITE:
          "/icons/settings/areas/semantic-search-white.svg",
        SENTIMENT_ANALYSIS: "/icons/settings/areas/sentiment-analysis.svg",
        SENTIMENT_ANALYSIS_WHITE:
          "/icons/settings/areas/sentiment-analysis-white.svg",
        SPEECH_RECOGNITION: "/icons/settings/areas/speech-recognition.svg",
        SPEECH_RECOGNITION_WHITE:
          "/icons/settings/areas/speech-recognition-white.svg",
        TEXT_GENERATION: "/icons/settings/areas/text-generation.svg",
        TEXT_GENERATION_WHITE:
          "/icons/settings/areas/text-generation-white.svg",
        TRANSLATION: "/icons/settings/areas/translation.svg",
        TRANSLATION_WHITE: "/icons/settings/areas/translation-white.svg",
        // ALGORITHMS ICONS
        ALGORITHM: "/icons/settings/algorithms/algorithm.svg",
        ALGORITHM_WHITE: "/icons/settings/algorithms/algorithm-white.svg",
        ANN: "/icons/settings/algorithms/ANN.svg",
        ANN_WHITE: "/icons/settings/algorithms/ANN-white.svg",
        APRIORI: "/icons/settings/algorithms/apriori.svg",
        APRIORI_WHITE: "/icons/settings/algorithms/apriori-white.svg",
        CUSTOM: "/icons/settings/algorithms/custom.svg",
        CUSTOM_WHITE: "/icons/settings/algorithms/custom-white.svg",
        DEEPLEARNING_CNN: "/icons/settings/algorithms/deep-learning-CNN.svg",
        DEEPLEARNING_CNN_WHITE:
          "/icons/settings/algorithms/deep-learning-CNN-white.svg",
        DEEPLEARNING_RNN: "/icons/settings/algorithms/deep-learning-RNN.svg",
        DEEPLEARNING_RNN_WHITE:
          "/icons/settings/algorithms/deep-learning-RNN-white.svg",
        KMEANS: "/icons/settings/algorithms/k-means.svg",
        KMEANS_WHITE: "/icons/settings/algorithms/k-means-white.svg",
        LSTM: "/icons/settings/algorithms/LSTM.svg",
        LSTM_WHITE: "/icons/settings/algorithms/LSTM-white.svg",
        PCA: "/icons/settings/algorithms/PCA.svg",
        PCA_WHITE: "/icons/settings/algorithms/PCA-white.svg",
        RANDOM_FOREST_V2: "/icons/settings/algorithms/random-forest-v2.svg",
        RANDOM_FOREST_V2_WHITE:
          "/icons/settings/algorithms/random-forest-v2-white.svg",
        SVMV2: "/icons/settings/algorithms/SVM-v2.svg",
        SVMV2_WHITE: "/icons/settings/algorithms/SVM-v2-white.svg",
        TRANSFORMATION: "/icons/settings/algorithms/transformation.svg",
        TRANSFORMATION_WHITE:
          "/icons/settings/algorithms/transformation-white.svg",
        XG_BOOST: "/icons/settings/algorithms/XGBoost.svg",
        XG_BOOST_WHITE: "/icons/settings/algorithms/XGBoost-white.svg",
        // OPERATIONS ICONS
        FINANCE: "/icons/settings/operations/finance.svg",
        FINANCE_WHITE: "/icons/settings/operations/finance-white.svg",
        HR: "/icons/settings/operations/HR.svg",
        HR_WHITE: "/icons/settings/operations/HR-white.svg",
        R_D: "/icons/settings/operations/R-D.svg",
        R_D_WHITE: "/icons/settings/operations/R-D-white.svg",
        SALES: "/icons/settings/operations/sales.svg",
        SALES_WHITE: "/icons/settings/operations/sales-white.svg",
        // CATEGORIES ICONS
        HIGH_RISK: "/icons/settings/risk-levels/high-risk.svg",
        MIDDLE_RISK: "/icons/settings/risk-levels/middle-risk.svg",
        NO_RISK: "/icons/settings/risk-levels/no-risk.svg",
      },
    },
  },
};
