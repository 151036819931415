import React from "react";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../../services/common/common.service";
import { WorkflowManagementContent } from "../../../modules/settings/components/workflow-management/workflow-management-content/workflow-management-content.component";
import SettingsHead from "../../../modules/settings/shared/settings-head/settings-head.component";
import { ContentLayout } from "../../../layouts/content/content.layout";
import { Box } from "@material-ui/core";

export default function WorkflowManagementPage() {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("workflow management"));

  return (
    <Box className="role-contain">
      <Box className="content">
        <SettingsHead
          title={t("workflow management")}
          subTitle={t("organize your workflows here")}
        />
        <ContentLayout>
          <WorkflowManagementContent />
        </ContentLayout>
      </Box>
    </Box>
  );
}
