import React, { useEffect, useContext } from "react";
import "./dashboard-content.component.scoped.scss";
// import { DashboardService } from "../../services/dashboard/dashboard.service";
import DashboardFirstBlock from "../dashboard-first-block/dashboard-first-block.component";
import DashboardSecondBlock from "../dashboard-second-block/dashboard-second-block.component";
import DashboardThirdBlock from "../dashboard-third-block/dashboard-third-block.component";
import { GlobalContext } from "../../../../store";
import { Box } from "@material-ui/core";
import PagesHeadComponent from "../../../../components/pages-head-component/pages-head.component";
import { icons } from "../../../../statics/icons";
import { useTranslation } from "react-i18next";
import { ContentLayout } from "../../../../layouts/content/content.layout";

export default function DashboardContent() {
  const [t] = useTranslation();
  const { helpData, setHelpContent } = useContext(GlobalContext);
  const handleCardsChange = (orderedCards: any[]) => {};

  useEffect(() => {
    setHelpContent(helpData.Dashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <PagesHeadComponent
        title={t("PAGES_HEAD.AI_RISK_LANDSCAPE_TITLE")}
        subTitle={t("PAGES_HEAD.AI_RISK_LANDSCAPE_SUB_TITLE")}
        logo={icons.PAGES.HEAD.AI_RISK_LANDSCAPE}
      />
      <ContentLayout>
        <Box className="dashboard">
          <Box className="theme-container dashboard-content">
            <DashboardFirstBlock onCardsChange={handleCardsChange} />
            <DashboardSecondBlock onCardsChange={handleCardsChange} />
            <DashboardThirdBlock onCardsChange={handleCardsChange} />
          </Box>
        </Box>
      </ContentLayout>
    </Box>
  );
}
