import "./select-users.dialog.scoped.scss";
import React, { useState, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Box } from "@material-ui/core";
import { CasesService } from "../../../../services/cases/cases.service";
import { CaseUser } from "../../../../types/case-user";
import { icons } from "../../../../statics/icons";
import { CreateUseCaseContext } from "../../../../store";

export const SelectUsersDialog = (props: any) => {
  const { onClose } = props;
  const casesService = new CasesService();
  const { useCase, setUseCase } = useContext(CreateUseCaseContext);

  const emptyArr: any[] = [];
  const emptyObj: any = {};

  const [isChanged, setIsChanged] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [_typeahead, setTypeahead] = useState(emptyObj);
  const [users, setUsers] = useState(emptyArr);
  const [userImageError, setUserImageError] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(useCase.users || emptyArr);
  // Track the index of the highlighted menu item.
  const [activeIndex, setActiveIndex] = useState(-1);
  const labelKey: any = "name";
  const [t] = useTranslation();

  const handleSearch = async (name: any) => {
    setSearchLoading(true);
    const usersResult = await casesService.searchUsers(name);
    setUsers(usersResult);
    setSearchLoading(false);
  };

  const selectUser = (option: CaseUser) => {
    const selected: any[] = [...selectedUsers];
    const selectedUsersNames = [
      ...selectedUsers.map((item: any) => item.email),
    ];

    if (selectedUsersNames.includes(option.email)) {
      return;
    } else {
      option.keyContact = false;
      selected.push(option);
      setSelectedUsers(selected);
      setIsChanged(true);
    }
    setTimeout(() => {
      _typeahead.clear();
      _typeahead.focus();
    }, 0);
  };

  const deleteUser = (option: CaseUser) => {
    const selected: any[] = [...selectedUsers];
    selected.splice(selected.indexOf(option), 1);
    setSelectedUsers(selected);
    setIsChanged(true);
  };

  const handleUpdate = async () => {
    if (selectedUsers.length) {
      selectedUsers[0].keyContact = true;
    }
    setUseCase({ ...useCase, users: [...selectedUsers] });
    handleClose(true);
  };

  const handleClose = (value: any) => {
    onClose(value);
  };

  const onKeyDown = useCallback(
    (e: any, cachedUsers: any) => {
      const code = e.keyCode || e.which;
      // Check whether the 'enter' key was pressed
      if (code === 13 && activeIndex !== -1) {
        selectUser(cachedUsers[activeIndex]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeIndex]
  );

  const handleFocus = (input: any) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 1);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName="modal-user"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("SELECT_AI_TEAM")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box className="user-search-icon">
          <img src={icons.search} alt="" />
        </Box>
        <Box className="typeahead-search">
          <AsyncTypeahead
            id="typeahead-search"
            isLoading={searchLoading}
            minLength={1}
            labelKey={labelKey}
            onSearch={handleSearch}
            options={users}
            placeholder={t("search for person")}
            promptText="Searching..."
            searchText="Searching..."
            ref={(ref) => {
              setTypeahead(ref);
              handleFocus(ref);
            }}
            onKeyDown={(e) => onKeyDown(e, [...users])}
            renderMenuItemChildren={(option: CaseUser, props: any) => (
              <Box
                className="user-search-option"
                onClick={() => selectUser(option)}
              >
                <Box className="user-info">
                  <Box className="user-img">
                    {option.profilePicURL && !userImageError ? (
                      <img
                        src={option.profilePicURL}
                        title={option.firstName + " " + option.lastName}
                        alt=""
                        onError={setUserImageError.bind(null, true)}
                      />
                    ) : (
                      <span className="text-uppercase">
                        {option.firstName
                          ? option.firstName[0] + option.lastName[0]
                          : ""}
                      </span>
                    )}
                  </Box>
                  <Box className="user-name trim">
                    <span>{option.name}</span>
                    <small>{option.email}</small>
                  </Box>
                </Box>
                <Box className="user-role">{t(option.role)}</Box>
                <Box className="user-type">{t(option.group)}</Box>
              </Box>
            )}
          >
            {(state: any) => {
              // Passing a child render function to the component exposes partial
              // internal state, including the index of the highlighted menu item.
              if (activeIndex !== state.activeIndex) {
                setActiveIndex(state.activeIndex);
              }
            }}
          </AsyncTypeahead>
        </Box>

        <Box className="selected-users-container">
          {selectedUsers.map((selectedUser: CaseUser, index: any) => {
            return (
              <Box className="selected-user" key={index}>
                <Box className="user-info">
                  <Box className="user-img">
                    {selectedUser.profilePicURL && !userImageError ? (
                      <img
                        src={selectedUser.profilePicURL}
                        title={
                          selectedUser.firstName + " " + selectedUser.lastName
                        }
                        alt=""
                        onError={setUserImageError.bind(null, true)}
                      />
                    ) : (
                      <span className="text-uppercase">
                        {selectedUser.firstName
                          ? selectedUser.firstName[0] + selectedUser.lastName[0]
                          : ""}
                      </span>
                    )}
                  </Box>
                  <Box className="user-name">
                    <span>
                      {selectedUser.name ||
                        selectedUser.firstName + " " + selectedUser.lastName}
                    </span>
                    <small>{selectedUser.email}</small>
                  </Box>
                </Box>
                <Box className="user-role">{t(selectedUser.role)}</Box>
                <Box className="user-type">{t(selectedUser.group)}</Box>
                <Box className="user-delete">
                  <ClearIcon onClick={() => deleteUser(selectedUser)} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme"
          onClick={handleClose.bind(null, false)}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="btn-primary-theme"
          onClick={handleUpdate}
          disabled={!isChanged}
        >
          <CheckIcon />
          {t("select")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
