import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../../services/common/common.service";
import { SettingsService } from "../../../services/settings/settings.service";
import { GlobalContext } from "../../../store";
import AddBranchContent from "../../../modules/settings/components/branch-management/add-branch-content/add-branch-content.component";
import UpdateBranchContent from "../../../modules/settings/components/branch-management/update-branch-content/update-branch-content.component";
import SettingsHead from "../../../modules/settings/shared/settings-head/settings-head.component";
import { Box } from "@material-ui/core";
import { ContentLayout } from "../../../layouts/content/content.layout";

export default function BranchManagementPage() {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  const settingsService: SettingsService = new SettingsService();
  commonService.setTitle(t("branch management"));

  const { setBranches } = useContext(GlobalContext);

  const [branchesUpdated, setBranchesUpdated] = useState(false);

  const setBranchAdded = () => {
    setBranchesUpdated(true);
    setTimeout(() => {
      setBranchesUpdated(false);
    }, 100);
  };

  async function getBranches() {
    const response = await settingsService.listAllBranches();
    setBranches(response);
  }

  useEffect(() => {
    getBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchesUpdated]);

  return (
    <Box className="role-contain">
      <Box className="content">
        <SettingsHead
          title={t("branch management")}
          subTitle={t("manage your departments or locations here")}
        />
        <ContentLayout>
          <AddBranchContent onBranchesChange={() => setBranchAdded()} />
          <UpdateBranchContent />
        </ContentLayout>
      </Box>
    </Box>
  );
}
