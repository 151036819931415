import { GuardsService } from "./../services/guards/guards.service";
import DashboardLayout from "../layouts/dashboard/dashboard.layout";
import SettingsLayout from "../layouts/settings/settings.layout";
import LoginPage from "../pages/login/login.page";
import UsersPage from "../pages/users/users.page";
import DashboardPage from "../pages/dashboard/dashboard.page";
import ProfilePage from "../pages/settings/profile/profile.page";
import RolesManagementPage from "../pages/settings/roles-managment/roles-management.page";
import TodoPage from "../pages/todo/todo.page";
import SignUpPage from "../pages/sign-up/sign-up.page";
import FallbackPage from "../pages/fallback/fallback";
import ForgotPasswordPage from "../pages/forgot-password/forgot-password.page";
import ResetPasswordPage from "../pages/reset-password/reset-password.page";
import Workspace from "../pages/settings/workspace/workspace.page";
import WorkflowManagementPage from "../pages/settings/workflow-management/workflow-management.page";
import TranslationManagementPage from "../pages/settings/translation-management/translation-management.page";
import BranchManagementPage from "../pages/settings/branch-management/branch-management.page";
import LogfilePage from "../pages/settings/logfile/logfile.page";
import CreateWorkSpacePage from "../pages/workspaces/create-workspace/create-workspace.page";
import WorkspacesPage from "../pages/workspaces/list-workspaces/list-workspaces.page";
import CreateWorkspaceOldPage from "../pages/workspaces/create-workspace-old/create-workspace-old.page";
import CasePage from "../pages/case/case-details/case.page";
import CreateCasePage from "../pages/case/create-case/create-case.page";
import PrintCasePage from "../pages/case/case-print/case-print.page";
// import ComingSoonPage from "../pages/comming-soon/coming-soon.page";
import AIProductsPage from "../pages/cases/ai-products/ai-products.page";
import AIInventoryPage from "../pages/cases/ai-inventory/ai-inventory.page";
import AIDemandPage from "../pages/cases/ai-demand/ai-demand.page";
import AITrackingPage from "../pages/cases/ai-tracking/ai-tracking.page";
import AIAssessmentPage from "../pages/cases/ai-assessment/ai-assessment.page";

const guardsService: GuardsService = new GuardsService();

const routes = [
  {
    path: "/login",
    title: "Login",
    component: LoginPage,
    canActivate: [guardsService.isNotAuthorized],
  },
  {
    path: "/signup/:id/:tenantId",
    title: "signup",
    component: SignUpPage,
  },
  {
    path: "/forgot-password",
    title: "forgot password",
    component: ForgotPasswordPage,
    canActivate: [guardsService.isNotAuthorized],
  },
  {
    path: "/reset-password/:id",
    title: "signup",
    component: ResetPasswordPage,
    canActivate: [guardsService.isNotAuthorized],
  },
  {
    path: "/workspaces",
    title: "workspaces",
    component: WorkspacesPage,
    canActivate: [guardsService.isAuthorized],
  },
  {
    path: "/On-Boarding",
    title: "Onboarding",
    component: CreateWorkSpacePage,
    canActivate: [guardsService.isSuperAdmin],
  },
  {
    path: "/",
    canActivate: [guardsService.isAuthorized],
    component: DashboardLayout,
    routes: [
      {
        path: "/create-workspace",
        title: "create workspace",
        component: CreateWorkspaceOldPage,
        canActivate: [guardsService.isSystemAdmin],
      },
      {
        path: "/landscape",
        title: "landscape",
        component: DashboardPage,
        canActivate: [guardsService.isCaseAdmin],
      },
      {
        path: "/ai-products",
        title: "AI-Products",
        component: AIProductsPage,
        canActivate: [guardsService.isCaseAdmin],
      },
      {
        path: "/ai-inventory",
        title: "AI-Inventory",
        component: AIInventoryPage,
        canActivate: [guardsService.isCaseAdmin],
      },
      {
        path: "/ai-demand",
        title: "AI Demand Manager",
        component: AIDemandPage,
        canActivate: [guardsService.isCaseAdmin],
      },
      {
        path: "/ai-compliance-tracking",
        title: "AI Compliance Tracking",
        component: AITrackingPage,
        canActivate: [guardsService.isCaseAdmin],
      },
      {
        path: "/ai-risk-assessment",
        title: "AI Risk Assessment",
        component: AIAssessmentPage,
        canActivate: [guardsService.isCaseAdmin],
      },
      {
        path: "/create-case",
        title: "Create Case",
        component: CreateCasePage,
        canActivate: [guardsService.isCreateCaseAuthorized],
      },
      {
        path: "/case/print",
        title: "Print Case",
        component: PrintCasePage,
        canActivate: [guardsService.isCaseAdmin],
      },
      {
        path: "/case/:id",
        title: "Case Details",
        component: CasePage,
        canActivate: [guardsService.isCaseAdmin],
      },

      {
        path: "/users",
        title: "Users",
        component: UsersPage,
        canActivate: [guardsService.isSystemAdmin],
      },
      {
        path: "/ai-tasks",
        title: "AI-Tasks",
        component: TodoPage,
        canActivate: [guardsService.isCaseAdmin],
      },
      {
        path: "/settings",
        component: SettingsLayout,
        routes: [
          // {
          //   path: "/settings/general",
          //   title: "Settings",
          //   component: SettingsPageOld,
          // },
          {
            path: "/settings/workspace-management",
            title: "Workspace Management",
            component: Workspace,
            canActivate: [guardsService.isSuperUser],
          },
          {
            path: "/settings/user-management",
            title: "User Management",
            component: ProfilePage,
          },
          {
            path: "/settings/roles-management",
            title: "Roles Management",
            component: RolesManagementPage,
            canActivate: [guardsService.isSystemAdmin],
          },
          {
            path: "/settings/workflow-management",
            title: "Workflow Management",
            component: WorkflowManagementPage,
            canActivate: [guardsService.isSystemAdmin],
          },
          {
            path: "/settings/translation-management",
            title: "Translation Management",
            component: TranslationManagementPage,
            canActivate: [guardsService.isSystemAdmin],
          },
          {
            path: "/settings/branch-management",
            title: "Branch Management",
            component: BranchManagementPage,
            canActivate: [guardsService.isSystemAdmin],
          },
          // {
          //   path: "/settings/reporter-configuration",
          //   title: "Reporter Configuration",
          //   component: ReporterConfigurationPage,
          //   canActivate: [guardsService.isSystemAdmin],
          // },
          // {
          //   path: "/settings/AI-management",
          //   title: "AI Data Management",
          //   component: AIManagementPage,
          //   canActivate: [guardsService.isSystemAdmin],
          // },
          {
            path: "/settings/logfile",
            title: "Logfile",
            component: LogfilePage,
            canActivate: [guardsService.isSystemAdmin],
          },
          {
            path: "/settings",
            redirect: "/settings/user-management",
          },
          {
            path: "*",
            component: FallbackPage,
          },
        ],
      },
      {
        path: "/",
        redirect: "/landscape",
      },
      {
        path: "/temp",
      },
      {
        path: "/",
        redirect: "/landscape",
      },
      {
        path: "/home",
        redirect: "/landscape",
      },
      {
        path: "*",
        // redirect: "/landscape",
        component: FallbackPage,
      },
    ],
  },
  {
    path: "*",
    redirect: "/landscape",
  },
];

export default routes;
