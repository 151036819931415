import React from "react";
import HeaderComponent from "../../../components/header/header.component";
import { CasesProvider } from "../../../store";
import { CommonService } from "../../../services/common/common.service";
import { useTranslation } from "react-i18next";
import ComingSoonComponent from "../../../components/coming-soon/coming-soon.component";
import PagesHeadComponent from "../../../components/pages-head-component/pages-head.component";
import { icons } from "../../../statics/icons";

const AITrackingPage = () => {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("PAGE_META_TITLES.AI_COMPLIANCE_TRACKING"));

  return (
    <CasesProvider>
      <HeaderComponent />
      <PagesHeadComponent
        title={t("PAGES_HEAD.AI_TRACKING_TITLE")}
        subTitle={t("PAGES_HEAD.AI_TRACKING_SUB_TITLE")}
        logo={icons.PAGES.HEAD.AI_TRACKING}
      />
      <ComingSoonComponent />
    </CasesProvider>
  );
};

export default AITrackingPage;
