import React, { useContext, useEffect, useState } from "react";
import "./AI-team-step.component.scoped.scss";
import { Box, Button, Radio, Typography } from "@material-ui/core";
import TabContentHeadComponent from "../tab-content-head/tab-content-head.component";
import { useTranslation } from "react-i18next";
import { CreateUseCaseContext } from "../../../../../store";
import { SelectUsersDialog } from "../../../dialogs/select-users/select-users.dialog";
import { teamColumns } from "../../../../../statics/table-columns/team-columns";
import { TableColumn } from "../../../../../types/table-column";
import { formatDate } from "../../../../../helpers";

const AITeamStepComponent = (props: any) => {
  const { onError } = props;
  const [t] = useTranslation();
  const { useCase, setUseCase } = useContext(CreateUseCaseContext);
  const [userImageError, setUserImageError] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);

  const onUpdateKeyContactUser = (id: number) => {
    const updatedUsers = useCase.users.map((user) => {
      // Set keyContact to true for the selected user, false for others
      return {
        ...user,
        keyContact: Number(user.id) === Number(id),
      };
    });
    setUseCase({ ...useCase, users: updatedUsers });
  };

  useEffect(() => {
    if (useCase.users.length === 0) {
      onError(true);
    } else {
      onError(false);
    }
  }, [useCase, onError]);

  return (
    <Box className="content">
      <TabContentHeadComponent
        title={t("USECASE_CREATE.AI_TEAM_TITLE")}
        subTitle={t("USECASE_CREATE.AI_TEAM_SUB_TITLE")}
      />
      <Box className="table-section mt-4">
        <Box className="tab-head mb-4 d-flex justify-content-between align-items-center">
          <Box className="head">
            <Typography variant="h5" className="title">
              {t("ASSIGNED_USERS")}
            </Typography>

            <Typography variant="body2" className="sub-title">
              {`${useCase.users.length} ${t("USER")} 
            (${
              useCase.users.filter((item: any) => item.role === "editor").length
            } ${t("editor")} / ${
                useCase.users.filter((item: any) => item.role === "auditor")
                  .length
              } ${t("AUDITOR")}
            )`}
            </Typography>
          </Box>

          <Button
            className="btn-primary-theme"
            variant="contained"
            size="small"
            color="primary"
            onClick={setShowUserModal.bind(null, true)}
          >
            {t("SELECT_AI_TEAM")}
          </Button>
        </Box>

        <Box className="tab-table">
          {/* Table Columns */}
          <Box className="tab-table-header">
            <Box className="d-flex align-items-center justify-content-between">
              {teamColumns.map((column: TableColumn, index: number) => {
                return (
                  <Box
                    className="tab-table-column"
                    key={index}
                    style={{ width: column.width }}
                  >
                    {t(column.fieldName)}
                  </Box>
                );
              })}
            </Box>
          </Box>
          {/* Table Rows */}

          <Box className="tab-table-body">
            {useCase.users && useCase.users.length === 0 && (
              <Box className="no-result">{t("NO_USERS_SELECTED_YET")}</Box>
            )}

            {useCase.users.map((row: any, rowIndex: number) => {
              return (
                <Box
                  className="tab-table-row d-flex justify-content-between"
                  key={rowIndex}
                >
                  {teamColumns.map(
                    (column: TableColumn, columnIndex: number) => {
                      const { type, value, secondaryValue }: any = column;
                      return (
                        <Box
                          className={
                            "tab-table-cell " +
                            (type === "string" ? " trim" : "")
                          }
                          key={columnIndex}
                          style={{ width: column.width }}
                        >
                          {type === "string" ? (
                            row[value]
                          ) : type === "date" ? (
                            formatDate(row[value], "DD.MM.YYYY")
                          ) : type === "client" ? (
                            <Box
                              className="d-flex align-items-center"
                              style={{ gap: ".5rem" }}
                            >
                              <Box className="profile-pic">
                                {row.profilePicURL && !userImageError ? (
                                  <img
                                    src={row.profilePicURL}
                                    alt=""
                                    onError={setUserImageError.bind(null, true)}
                                  />
                                ) : (
                                  <span className="text-uppercase">
                                    {row.firstName[0] + row.lastName[0]}
                                  </span>
                                )}
                              </Box>
                              <Box>
                                <Box className="user-cell text-capitalize">
                                  {row.firstName + " " + row.lastName}
                                </Box>
                                <Box className="branch-name">
                                  {row.type === "superUser"
                                    ? t("coordinator")
                                    : (row.role === "auditor"
                                        ? t("AUDITOR")
                                        : t("EDITOR")) +
                                      "/" +
                                      t(row[secondaryValue])}
                                </Box>
                              </Box>
                            </Box>
                          ) : type === "keyContact" ? (
                            <Box>
                              <Radio
                                checked={row.keyContact}
                                onChange={(e: any) => {
                                  onUpdateKeyContactUser(e.target.value);
                                }}
                                value={row.id}
                                name="radio-buttons"
                              />
                            </Box>
                          ) : type === "email" ? (
                            <Box className="user-cell">{row[value]}</Box>
                          ) : (
                            <Box className={type}>{row[value]}</Box>
                          )}
                        </Box>
                      );
                    }
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
        {showUserModal && (
          <SelectUsersDialog
            show={showUserModal}
            onClose={() => setShowUserModal(false)}
            isEditable={true}
          />
        )}
      </Box>
    </Box>
  );
};

export default AITeamStepComponent;
