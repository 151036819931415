import React from "react";
import { useTranslation } from "react-i18next";

import { CommonService } from "../../../services/common/common.service";
import { LogsProvider } from "../../../store";
import { LogfileContent } from "../../../modules/settings/components/logfile/logfile-content/logfile.component";
import SettingsHead from "../../../modules/settings/shared/settings-head/settings-head.component";
import { ContentLayout } from "../../../layouts/content/content.layout";
import { Box } from "@material-ui/core";

export default function LogfilePage() {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("logfile"));

  return (
    <LogsProvider>
      <Box className="role-contain">
        <Box className="content">
          <SettingsHead
            title={t("logfile")}
            subTitle={t("logfile description")}
          />
          <ContentLayout>
            <LogfileContent />
          </ContentLayout>
        </Box>
      </Box>
    </LogsProvider>
  );
}
