import React from "react";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../../services/common/common.service";
import WorkspaceManagement from "../../../modules/settings/components/workspace/workspace-management/workspace-management.component";
import SettingsHead from "../../../modules/settings/shared/settings-head/settings-head.component";
import { ContentLayout } from "../../../layouts/content/content.layout";
import { Box } from "@material-ui/core";

export default function Workspace() {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  commonService.setTitle(t("workspace management"));

  return (
    <Box className="role-contain">
      <Box className="content">
        <SettingsHead
          title={t("workspace management")}
          subTitle={t("organize the information of your workspace here")}
        />
        <ContentLayout>
          <WorkspaceManagement />
        </ContentLayout>
      </Box>
    </Box>
  );
}
