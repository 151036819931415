import React, { useState, useContext, useEffect, useRef } from "react";
import "./header.component.scoped.scss";
import { icons } from "../../statics/icons";
import Dropdown from "react-bootstrap/Dropdown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { history } from "react-router-guard";
import { GlobalContext } from "../../store";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import NotificationComponent from "../notification-component/notification-component.component";
import { NotificationsService } from "../../services/notifications/notifications.service";
import { MapperService } from "../../services/mapper/mapper.service";
import Drawer from "@material-ui/core/Drawer";
import useOutsideAlerter from "../../hooks/useOutisdeAlert";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";

export default function HeaderComponent(props: any) {
  const [t] = useTranslation();
  const localStorageService: LocalStorageService = new LocalStorageService();
  const dashboardService: DashboardService = new DashboardService();
  const notificationsService: NotificationsService = new NotificationsService();
  const mapperService: MapperService = new MapperService();
  const notificationsRef: any = useRef(null);

  const {
    setLang,
    lang,
    user,
    notifications,
    unseenNotifications,
    setUnseenNotifications,
    setNotifications,
    helpContent,
    setHelpData,
    setHelpContent,
    userTenants,
    setUserTenants,
  } = useContext(GlobalContext);
  const [userImageError, setUserImageError] = useState(false);

  let routeName = "/";
  if (history.location.pathname !== "/temp") {
    routeName = history.location.pathname;
  }
  const selectLang = (newLang: string) => {
    if (lang === newLang) return;
    localStorageService.setData("lang", newLang);
    setLang(newLang);
    history.replace("/temp");
    setTimeout(() => {
      history.replace(routeName);
    });
    getHelpData();
  };

  const emptyArr: any = [];
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [page, setPage] = useState(1);

  useOutsideAlerter(notificationsRef, () => setNotificationsOpen(false));

  const showSidebar = () => {
    if (notificationsOpen) setNotificationsOpen(false);
    setSidebar(!sidebar);
  };

  const getHelpData = async () => {
    const helpData = await dashboardService.getHelpData();
    await setHelpData(helpData);
    setHelpContent(helpData.Dashboard);
  };

  const onScroll = async () => {
    if (notificationsRef.current) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = notificationsRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        const loadedNotifications = await notificationsService.getNotifications(
          page + 1,
          15
        );
        const mappedLoaddedNotifications = mapperService.mapNotifications(
          loadedNotifications
        );
        const allNotifications = [
          ...notifications,
          ...mappedLoaddedNotifications,
        ];

        setNotifications(allNotifications);
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  const openNotifications = async () => {
    if (sidebar) setSidebar(false);
    setNotificationsOpen(!notificationsOpen);
    if (unseenNotifications.length > 0) {
      await notificationsService.makeNotificationsSeen();
      setUnseenNotifications(emptyArr);
      if (userTenants.length > 1 && user.role === "editor") {
        const userTenantsCopy = [...userTenants];
        userTenantsCopy.forEach((item: any) => {
          if (item.id === localStorageService.getData("tenantId")) {
            item.notificationsNumber = 0;
          }
        });
        setUserTenants(userTenantsCopy);
      }
    }
  };

  // scroll to top on history change
  useEffect(() => {
    const scrollable: any = document.querySelector(".content");
    const unlisten = history.listen(() => {
      scrollable.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <header className="main-header">
        <div className="header-content">
          {/* <div className="left-content">
            <div className="header-search">
              <img src={icons.search} alt="" />
              <HeaderSearchComponent />
            </div>
          </div> */}
          <div className="right-content">
            {/* {(user.type === "superAdmin" || user.role === "coordinator") && (
              <div className="mr-3">
                <Button
                  className="btn-primary-theme btn-save"
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => history.push("/create-case")}
                >
                  {t("CREATE_USECASE")}
                </Button>
              </div>
            )} */}
            <div className="header-item username">
              {`${user.title ? user.title : ""} ${user?.firstName} ${
                user?.lastName
              }`}
              <Box className="profile-pic">
                {user.profilePicURL && !userImageError ? (
                  <img
                    src={user.profilePicURL || icons.PLACEHOLDER.user}
                    title={user.firstName + " " + user.lastName}
                    alt=""
                    onError={setUserImageError.bind(null, true)}
                  />
                ) : (
                  <span className="text-uppercase">
                    {user.firstName ? user.firstName[0] + user.lastName[0] : ""}
                  </span>
                )}
              </Box>
            </div>
            <div
              className="header-item help"
              style={{
                margin: "0 15px",
                backgroundColor: sidebar ? "rgb(211, 214, 195)" : "inherit",
              }}
              onClick={showSidebar}
            >
              <img src={icons.help} alt="help" className="" />
            </div>
            {user.type && user.type === "user" && (
              <div
                className="header-item notifications"
                onClick={openNotifications}
                style={{
                  backgroundColor: notificationsOpen
                    ? "rgb(211, 214, 195)"
                    : "inherit",
                }}
              >
                <img
                  src={icons.notifications}
                  alt="help"
                  className="notifications-icon"
                />
                {unseenNotifications.length > 0 && (
                  <div className="rounded-notifications-number">
                    {unseenNotifications.length}
                  </div>
                )}
              </div>
            )}
            <div className="header-item language">
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  className="language-switch-btn"
                >
                  {/* <img src={icons[lang.toLowerCase()]} alt=""></img> */}
                  {lang.toUpperCase()}
                  <ExpandMoreIcon className="expand-more" />
                  <ExpandLessIcon className="expand-less" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={selectLang.bind(null, "de")}>
                    {/* <img src={icons.de} alt=""></img> */}
                    DE
                  </Dropdown.Item>
                  <Dropdown.Item onClick={selectLang.bind(null, "en")}>
                    {/* <img src={icons.en} alt=""></img> */}
                    EN
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {props.children}
      </header>
      {notificationsOpen ? (
        <div
          className={
            notificationsOpen
              ? "notifications-wrapper active"
              : "notifications-wrapper"
          }
          onScroll={() => onScroll()}
          ref={notificationsRef}
        >
          <div className="notifications-title">Notifications</div>
          {notifications.map((item: any, index: number) => {
            return <NotificationComponent key={index} notification={item} />;
          })}
          {notifications.length === 0 && (
            <p className="no-data">{t("no notifications yet")}</p>
          )}
        </div>
      ) : null}
      {sidebar ? (
        <Drawer
          anchor={"right"}
          open={sidebar}
          onClose={() => setSidebar(!sidebar)}
        >
          <aside className={sidebar ? "help-aside active" : "help-aside"}>
            <div className="mx-4 mt-4">
              <div className="upper-part">
                <p>Hilfe</p>
                <img
                  src={icons.close}
                  onClick={() => setSidebar(false)}
                  className="close-icon"
                  alt="close-help"
                />
              </div>
              <div className="pb-2">
                <p dangerouslySetInnerHTML={{ __html: helpContent }} />
              </div>
            </div>
          </aside>
        </Drawer>
      ) : null}
    </>
  );
}
